<script lang="js">
import Menu from './Menu.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'TheMainMenu',
  extends: Menu,
  computed: {
    ...mapGetters('profile', ['can']),
    items() {
      const items = [
        {
          icon: 'fa-suitcase',
          title: this.$tc('logs.title', 2),
          route: { name: 'logs.index' },
          permission: 'log.read',
        },
      ];
      return items.filter(item => this.can(item.permission));
    },
  },
};
</script>
